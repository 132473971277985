import React from "react"
import styled from "styled-components"
import H1 from "./h1"
import Layout from "./layout"
import Narrow from "./narrow"
import Img from "gatsby-image"
import Contentbox from "./contentbox"
import { colors } from "../utils/siteVars"
import Button from "./button"

const StyledProductPageTemplate = styled.div`
  .centered {
    margin: 0 100px;
    margin-bottom: 2rem;
  }
  .product-facts {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    &.no-specs {
      grid-template-columns: 1fr;
    }
    .full-width {
      grid-column: 1/3;
      margin: auto;
    }
    h3 {
      color: ${colors.green};
      margin: 0;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    ul {
      list-style: initial;
      margin-left: 1rem;
      width: 80%;
    }
  }
  .image-container {
    margin-top: 1rem;
    &.narrow {
      padding: 0 20%;
    }
  }
  @media (max-width: 768px) {
    .centered {
      margin: 0 0 2rem 0;
    }
    .product-facts {
      display: block;
      div {
        margin-bottom: 1rem;
      }
    }
    .image-container {
      &.narrow {
        padding: 0;
      }
    }
  }
`

const ProductPageTemplate = props => {
  const { product, additionalContent } = props
  return (
    <Layout
      headerText={product.name}
      headerImage={product.mainImage}
      helmet={product.name}
    >
      <StyledProductPageTemplate>
        <Narrow>
          <H1>{product.name}</H1>
          <p className="centered">{product.description}</p>
          <Img fluid={product.mainImage} alt="" />
          {product.facts ? (
            <Contentbox
              className={`product-facts ${product.specs ? "" : "no-specs"}`}
            >
              <div>
                <h3>Fakta</h3>
                {product.facts}
              </div>
              {product.specs ? (
                <div>
                  <h3>Specifikationer</h3>
                  {product.specs}
                </div>
              ) : null}
              {product.pdfLink ? (
                <div className="full-width">
                  <a
                    href={product.pdfLink}
                    download={product.pdfLink.replace("/", "")}
                  >
                    <Button>Ladda ned PDF</Button>
                  </a>
                </div>
              ) : null}
            </Contentbox>
          ) : null}
          {product.secondImage ? (
            <div
              className={`image-container ${
                product.secondImageNarrow ? "narrow" : ""
              }`}
            >
              <Img fluid={product.secondImage} alt="" />
            </div>
          ) : null}
          {product.thirdImage ? (
            <div
              className={`image-container ${
                product.thirdImageNarrow ? "narrow" : ""
              }`}
            >
              <Img fluid={product.thirdImage} alt="" />
            </div>
          ) : null}
          {product.fourthImage ? (
            <div
              className={`image-container ${
                product.fourthImageNarrow ? "narrow" : ""
              }`}
            >
              <Img fluid={product.fourthImage} alt="" />
            </div>
          ) : null}
        </Narrow>
        {additionalContent}
      </StyledProductPageTemplate>
    </Layout>
  )
}

export default ProductPageTemplate

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProductPageTemplate from "../../components/productPageTemplate"
import Img from "gatsby-image"
import Narrow from "../../components/narrow"

const NiclolasPage = () => {
  const query = useStaticQuery(graphql`
    query nicolasQuery {
      mainImage: file(relativePath: { eq: "nicholas_3.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      secondImage: file(relativePath: { eq: "nicholas_4.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      thirdImage: file(relativePath: { eq: "nicholas_3.jpeg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      slang1: file(relativePath: { eq: "Slang_1.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      slang2: file(relativePath: { eq: "Slang_2.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const product = {
    name: "Nicholas",
    mainImage: query.mainImage.childImageSharp.fluid,
    secondImage: query.secondImage.childImageSharp.fluid,
    thirdImage: query.thirdImage.childImageSharp.fluid,
    secondImageNarrow: false,
    thirdImageNarrow: false,
    description:
      "Väggförankrad, ergonomisk cykeltvätt. För inomhus- och utomhusbruk. Slangvinda kan monteras i linje med Nicholas för enklare rengöring.",
    facts: (
      <ul>
        <li>
          Idealisk för serviceverkstäder, cykeluthyrning och i anslutning till
          cykelförråd.{" "}
        </li>
        <li>
          Höga fasta stänkbleck på sidor och bakstycke. Löstagbart lägre
          stänkbleck i framkant.
        </li>
        <li>Cykeltvätten placeras på golv och monteras mot vägg.</li>
        <li>Skruv för montering i vägg ingår. </li>
        <li>
          Gallerstag av fyrkantiga profilrör som vinklats så risken för stänk
          minimeras, samtidigt som det ger bra stöd för placering av cykeln på
          stagen.
        </li>
        <li>Stora, lättåtkomliga sandfång i framkant och vinklad botten. </li>
        <li>
          Upphängningsstag, krokar samt hylla för avställning av flaskor och
          rengöringshjälpmedel ingår.{" "}
        </li>
        <li>Vid rengöring lyfts gallerstagen enkelt ur.</li>
        <li>Utlopp till höger. </li>
        <li>Ställbara fötter.</li>
      </ul>
    ),
    // specs: (
    //   <ul>
    //     <li>Design av proffesionellt skola</li>
    //     <li>Tillverkad i stöd plåt, 2 stansade stycken</li>
    //     <li>Pulverlack,vattenbaserad milj-lack</li>
    //     <li>5 olika kulörer, tillval er egen färg</li>
    //     <li>Avrinningshål för skyddande av hållare o däck</li>
    //     <li>Vikt 2400g</li>
    //     <li>Mått. H320,b270,t 110.</li>
    //     <li>Monteringshöjd 900-1300 mm</li>
    //     <li>Svensktillverkad rundade kanter för minimalt skaderisk</li>
    //   </ul>
    // ),
  }

  return (
    <ProductPageTemplate
      product={product}
      additionalContent={
        <div>
          <Narrow>
            <div className="image-container narrow">
              <Img fluid={query.slang1.childImageSharp.fluid} alt="" />
            </div>
            <div className="image-container narrow">
              <Img fluid={query.slang2.childImageSharp.fluid} alt="" />
            </div>
          </Narrow>
        </div>
      }
    />
  )
}
export default NiclolasPage
